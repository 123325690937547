.AuthBackground {
    width: 100vw;
    height: 100vh;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    display: grid;
    place-content: center;
    /* background-image: url('../../assets/BackgroundPixel.png');
    background-repeat: repeat, repeat;
    background-position: center;
    background-size:cover; */
    background: linear-gradient(45deg, #009298, #15588D, #00E7F1, #1C75BC, #00C2CB, #3F9AE2);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
@keyframes gradient {
	0% { background-position: 0% 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0% 50%; }
}

.AuthBox{
    width: 400px;
    height: 400px;
    background: rgba(255, 255, 255, 0.75);
    backdrop-filter: blur(10px);
    border-radius: 25px;
    padding: 48px;
    text-align: center;
}

.BridgelinxLogo {
    width: 150px;
    height: auto;
    margin-bottom: 48px;
}

.AuthNumberPrefix {
    width: 88px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
    border-right: 0px;
    border-radius: 5px 0px 0px 5px;
    padding: 0px 12px;
}  

.FlagIcon {
    width: 24px;
    height: auto;
    margin-right: 10px;
}

.CountryCode {
    font-family: 'proxima_novabold';
    font-size: 18px;
    margin: 0;
}

.ant-input.InputAuthNumber {
    height: 50px;
    font-family: 'proxima_novabold';
    font-size: 18px;
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
    border-radius: 0px 5px 5px 0px;
    padding: 0;
    padding-left: 12px;
}

.ant-btn.AuthSubmitButton, .ant-btn.AuthSubmitButton:hover, 
.ant-btn.AuthSubmitButton:focus, .ant-btn.AuthSubmitButton:active {
    width: 100%;
    height: 50px;
    font-family: 'proxima_novabold';
    font-size: 18px;
    color: #FFFFFF;
    background-color: #1C75BC;
    border-radius: 5px;
    border: 0px;
}

.Shift {
    /* font-family: 'proxima_novabold'; */
    position: absolute;
    left: 0;
    right: 0;
    bottom: 48px;
    margin: 0px;
}

.ShiftSpan {
    font-family: 'proxima_novaextrabold';
    color: #1C75BC;
    white-space: pre;
}

.ShiftSpan:hover {
    background-color: #1C75BC;
    color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    height: 24px;
}

.ant-input.VerificationCode, .ant-input.InputName {
    height: 50px;
    font-family: 'proxima_novabold';
    font-size: 18px;
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
    border-radius: 5px;
    padding-left: 12px;
}

.ant-input.VerificationCode {
    text-align: center;
}

.ant-spin.ant-spin-spinning.Loading {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Loading > .ant-spin-text {
    font-family: 'proxima_novabold';
    font-size: 18px;
    margin-top: 12px;
}