/* ======================================== ANTD IMPORT ======================================== */
@import '~antd/dist/antd.css';

/* ======================================== FONT IMPORTS ======================================== */
@font-face {
	font-family: 'Regular';
	src: url('assets/fonts/ProximaNova-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Bold';
	src: url('assets/fonts/ProximaNova-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

/* ======================================= HELPER CLASSES ======================================= */
.FullWidthHeight { width: 100%; height: 100%; }
.FullWidth { width: 100%; }
.FullHeight { height: 100%; }
.HalfHeight { height: 50%; }
.QuarterHeight { height: 25%; }
.ThreeFourthWidth { width: 75%; }
.HalfWidth { width: 50%; }
.QuarterWidth { width: 25%; }
.SixtyFourtyWidth { width: 60%;}
.FourtySixtyWidth { width: 40%;}
.Height72 {height: 72px;}
.Height60 {height: 60px;}

.DisplayFlex { display: flex; }
.FlexRow { display: flex; align-items: center; }
.FlexColumn { display: flex; flex-direction: column; }
.FlexRowBox { display: flex; align-items: center; justify-content: center; }
.FlexColumnBox { display: flex; flex-direction: column; align-items: center; justify-content: center; }
.FlexRowSpace { display: flex; align-items: center; justify-content: space-between; }
.FlexColumnSpace { display: flex;  flex-direction: column; justify-content: space-between; }
.FlexTopRow { display: flex;  align-items: flex-start; justify-content: space-between; }
.FlexDirectionRow { display: flex; flex-direction: row !important;}

.NoMargin { margin: 0 !important;}
.Margin { margin: 24px; }
.MarginTop { margin-top: 24px; }
.MarginBottom { margin-bottom: 24px !important; }
.MarginLeft { margin-left: 24px; }
.MarginRight { margin-right: 24px; }
.HalfMargin { margin: 12px; }
.HalfMarginTop { margin-top: 12px; }
.HalfMarginBottom { margin-bottom: 12px; }
.HalfMarginLeft { margin-left: 12px; }
.HalfMarginRight { margin-right: 12px; }
.MarginRight8 { margin-right: 8px; }


.NoPadding { padding: 0 !important;}
.Padding { padding: 24px; }
.PaddingTop { padding-top: 24px; }
.PaddingBottom { padding-bottom: 24px; }
.PaddingLeft { padding-left: 24px; }
.PaddingRight { padding-right: 24px; }
.HalfPadding { padding: 12px; }
.HalfPaddingTop { padding-top: 12px; }
.HalfPaddingBottom { padding-bottom: 12px; }
.HalfPaddingLeft { padding-left: 12px; }
.HalfPaddingRight { padding-right: 12px; }
.PaddingExceptBottom { padding: 24px; padding-bottom: 0px; }
.HalfPaddingExceptBottom { padding: 12px; padding-bottom: 0px; }
.QuarterPaddingLeftRight { padding-left: 6px; padding-right: 6px; }

.PrimaryColor { color: #1C75BC; }
.PrimaryTintColor { color: #3F9AE2; }
.PrimaryShadeColor { color: #15588D; }
.SecondaryColor { color: #00C2CB; }
.SecondaryTintColor { color: #00E7F1; }
.SecondaryShadeColor { color: #009298; }
.BlackColor { color: #333333; }
.WhiteColor { color: #FFFFFF; }
.GrayColor { color: #808080; }
.DarkGrayColor { color: #757575; }
.BackgroundColor { color: #EDF7FF; }
.ErrorColor {color: #F44336;}
.SuccessColor {color: #00800B}
.MustardColor{ color: #FF9800;}

.NoOpacity { opacity: 0; }
.OverflowScroll { overflow-y: scroll; }

.RegularFont { font-family: 'Regular'; }
.BoldFont { font-family: 'Bold'; }
.FontSize12 { font-size: 12px; }
.FontSize16 { font-size: 16px; }
.FontSize18 { font-size: 18px; }
.Capitalize { text-transform: capitalize; }
.LeftAlign { text-align: left; }
.RightAlign { text-align: right; }
.CenterAlign {text-align: center;}

.Border { border: 1px solid #E0E0E0;}
.BorderTop { border-top: 1px solid #E0E0E0;}
.BorderBottom { border-bottom: 1px solid #E0E0E0;}
.BorderLeft { border-left: 1px solid #E0E0E0;}
.BorderRight { border-right: 1px solid #E0E0E0;}

.BorderRadius {border-radius: 5px;}

.MapContainer { background-color: #FAFAFA; }
.CardHeaderColor { background-color: #EDF7FF; }
.GrayBackgroundColor { background-color: #F2F2F2; }
.CursorPointer { cursor: pointer; }

/* ======================================== BASE CLASSES ======================================== */
html, body, #root, .Layout {
	width: 100vw;
	height: 100vh;
	font-family: 'Regular' !important;
	font-size: 14px;
	color: #333333;
	padding: 0px;
	margin: 0px;
	overscroll-behavior: none;
}

/* ::-webkit-scrollbar {
	display: none;
} */

p {
	margin: 0px
}

/* ========================================== SPINNER ========================================== */
.Spinner{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background:rgba(255,255,255,0.5);
	backdrop-filter: blur(1px);
	z-index: 10;
}

.SpinnerDescription {
	font-family: 'Bold';
	font-size: 16px;
	margin-top: 24px;
}

/* =========================================== RATIOS =========================================== */
.SquareRatio, .HalfSquareRatio, .ThreeFourthSquareRatio, .QuarterSquareRatio, .FourtySquareRatio {
	position: relative;
    width: 100%;
}

.SquareRatio:before {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.ThreeFourthSquareRatio::before {
	content: "";
	display: block;
	padding-bottom: 75%;
}

.HalfSquareRatio::before {
	content: "";
	display: block;
	padding-bottom: 50%;
}

.FourtySquareRatio {
	content: "";
	display: block;
	padding-bottom: 40%;
}

.QuarterSquareRatio::before {
	content: "";
	display: block;
	padding-bottom: 25%;
}

.SolidifyRatio {
	position: absolute;
	height: 100%;
	inset: 0px 0px 0px 0px;
}

/* ========================================== LAYOUTS ========================================== */
.ant-layout-sider {
	width: 192px !important;
	height: 100%;
	background-color: #FFFFFF;
	border-left: 1px solid #E5E5E5;
	border-right: 1px solid #E5E5E5;
}

.ant-layout-header {
	height: 72px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #FFFFFF;
	border-bottom: 1px solid #E5E5E5;
	line-height: initial;
	padding: 0px 24px;
}

.ant-layout-content {
	position: relative;
	background-color: #FFFFFF;
	padding: 24px;
	overflow: scroll;
}

/* ========================================== SIDEBAR ========================================== */
.SidebarIcon {
	width: 20px;
	height: 20px;
	margin-right: 12px;
	fill: #1C75BC;
}

.ant-menu-inline {
	border: 0px;
}

.ant-menu {
	margin-top: 72px;
}

.ant-menu-item {
	height: 60px !important;
	font-family: 'Bold';
	margin: 0px !important;
	padding: 0px !important;
	padding-left: 24px !important;
}

.ant-menu-title-content {
	margin: 0px !important;
}

.ant-menu-item-selected::after {
	display: none;
}

.ant-menu-item a {
	color: #333333 !important;
}

.ant-menu-item-selected {
	width: 100% !important;
	background: #EDF7FF;
	border-radius: 0px 100px 100px 0px;
}

.ant-menu-item a:hover, 
.ant-menu-item-selected a, 
.ant-menu-item-selected a:hover {
	color: #1C75BC !important;
}

/* ========================================== HEADER ========================================== */
.Heading {
	font-family: 'Bold';
	font-size: 16px;
}

.ant-breadcrumb-separator {
	font-family: 'Bold';
	font-size: 16px;
	margin: 0px 6px;
}


/* ========================================== CARD DESIGN ========================================== */
.ant-card {
	border: 1px solid #E0E0E0;
	border-radius: 5px;
}

.ant-card-head, .ant-modal-header {
	min-height: initial;
	max-height: 72px;
	font-family: 'Bold';
	font-size: 16px;
	background: #EDF7FF;
	padding: 0px 24px;
	border-bottom: 0px; 
	border-radius: 5px 5px 0px 0px;
}

.ant-card-head-wrapper {
	width: 100%;
	height: 100%;
}

.ant-card-head-title, .ant-modal-title {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	color: #1C75BC;
	padding: 0px;
}

.TitleDescription {
	font-family: 'Regular';
	font-size: 12px;
	color: #808080
}

.ant-card-body, .ant-modal-body {
	display: flex;
	flex-direction: column;
	padding: 0px;
	overflow-y: scroll;
	border-top: 1px solid #E0E0E0 !important;
}
.ant-card-body {
	border-radius: 0px 0px 5px 5px;
}

.ant-modal-body {
	background-color: #F5F5F5;
}

.ant-modal-content {
	border-radius: 5px;
}

/* .ant-form-item:last-child {
	margin-bottom: 0px;
} */

.ant-form-item-label {
	font-family: 'Bold';
	font-size: 16px;
	padding-bottom: 0px !important;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
	display: inline-block;
	content: '*';
    color: #ff0000;
    margin-left: 6px;
}


.AddOn > .ant-input-group {
	height: 100%;
}
.AddOn > .ant-input-group > .ant-input-group-addon {
	height: 100%;
	font-family: 'Bold';
	color: #333333;
	background-color: #F5F5F5;
	border: 0;
}
.AddOn > .ant-input-group > .ant-input {
	height: 100%;
	border: 0;
}

.AddOn > .ant-input-group > *:first-child,
.ant-input-number-group > *:first-child {
	border-radius: 5px 0px 0px 5px !important;
}
.AddOn > .ant-input-group > *:last-child,
.ant-input-number-group > *:last-child {
	border-radius: 0px 5px 5px 0px !important;
}

.ant-input-number-group > .ant-input-number-group-addon {
	font-family: 'Bold';
	color: #333333;
	background-color: #F5F5F5;
}


.ant-form-item-has-error > .ant-col > .ant-form-item-control-input > .ant-form-item-control-input-content > .InputField.AddOn {
	border: 1px solid #F44336 !important;
}
.ant-form-item-has-error > .ant-form-item-control > div > div > span > .UploadImages {
	border: 1px dashed #F44336 !important;
}

/* ======================================= Live Tracking ======================================= */
.DriversList {
	width: 20%;
	border-right: 1px solid #E0E0E0;
	overflow: scroll;
}

.DriverData {
	padding: 12px 24px ;
	border-bottom: 1px solid #E0E0E0;
}
.DriverData:last-child {
	border: 0px;
}

.DriverName {
	font-family: 'Bold';
	font-size: 16px;
}

.TrackingMap {
	width: 80%;
	background-color: #E0E0E0;
}

/* =========================================== Button =========================================== */
.Button,
.Button:hover, 
.Button:active, 
.Button:focus{
	font-family: 'Bold';
	font-size: 16px;
	color: #FFFFFF;
	border: 0;
	border-radius: 5px;
	padding: 0px;
}

.SecondaryButton,
.SecondaryButton:hover,
.SecondaryButton:active, 
.SecondaryButton:focus {
	height: 48px;
	background-color: #EDF7FF ;
	color: #1C75BC;
	border: 1px solid #1C75BC;
	padding: 0px 24px;
}

.DashedButton,
.DashedButton:hover,
.DashedButton:active, 
.DashedButton:focus {
	height: 48px;
	font-size: 14px;
	background-color: #FFFFFF;
	color: #808080;
	border: 1px dashed #808080;
	padding: 0px 24px;
}

.PrimaryButton,
.PrimaryButton:hover,
.PrimaryButton:active, 
.PrimaryButton:focus {
	min-width: 144px;
	height: 48px;
	background-color: #1C75BC;
	color: #FFFFFF;
	padding: 0px 24px;
}

.DuplicateOrderButton,
.DuplicateOrderButton:hover,
.DuplicateOrderButton:active, 
.DuplicateOrderButton:focus {
	min-width: 144px;
	height: 48px;
	background-color: #F2F2F2;
	color: #333333;
	border: 1px solid #1f1c1c;
	padding: 0px 24px;
}

.ModalButton, 
.ModalButton:hover, 
.ModalButton:active, 
.ModalButton:focus  {
	font-size: 14px;
	background-color: #F2F2F2;
	border: 1px solid #808080;
	color: #333333;
	padding: 0px 24px;
	margin-bottom: 12px;
}
.AddModalButton {height: 60px;}
.EditModalButton {height: 72px;}
.AddModalButton:only-child, .EditModalButton:only-child {margin-bottom: 0px !important;}
/* .ant-btn.Button.ModalButton.EditModalButton:last-of-type {margin-bottom: 24px !important;} */
.PrimaryModalButton { background-color: #E5F0FF !important; }
.SecondaryModalButton { background-color: #E5FFFF !important; }

.ModalButton::after { --antd-wave-shadow-color: #333333; }
.DuplicateOrderButton::after { --antd-wave-shadow-color: #333333; }
.PrimaryModalButton::after { --antd-wave-shadow-color: #1C75BC; }
.SecondaryModalButton::after { --antd-wave-shadow-color: #00C2CB; }

.LocationSummary { width: calc(100% - 56px) !important; }

.TableButton,
.TableButton:hover,
.TableButton:active,
.TableButton:focus {
	height: 28px;
	font-size: 10px;
	background-color: #f5f5f5;
	color: #FFFFFF;
	padding: 0px 6px;
}

/* ==================================== ANTD TABLE SETTINGS ==================================== */
.ant-table.ant-table-bordered > .ant-table-container {
	border: 1px solid #E0E0E0;
	border-radius: 5px;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
	border-bottom: 1px solid #E0E0E0;
}

.ant-table-thead > tr > th {
	/* height: 72px; */
	font-family: 'Bold';
	/* font-size: 16px; */
	background-color: #EDF7FF !important;
	/* color: #1C75BC; */
	text-align: center;
}

td.ant-table-column-sort {
	background-color: #FFFFFF;
} 

.ant-table-container table > thead > tr:first-child th:first-child {
	border-top-left-radius: 5px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
	border-top-right-radius: 5px;
}
.ant-table-container table > tbody > tr:last-child td:first-child {
	border-bottom-left-radius: 5px;
}
.ant-table-container table > tbody > tr:last-child td:last-child {
	border-bottom-right-radius: 5px;
}

.ant-table-thead > tr > th, 
.ant-table-tbody > tr > td, 
.ant-table tfoot > tr > th, 
.ant-table tfoot > tr > td {
	padding: 12px !important;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table, 
.ant-table-tbody > tr > td > .ant-table-expanded-row-fixed > .ant-table-wrapper:only-child .ant-table {
	margin: 0px;	
}


/* tr.ant-table-expanded-row > td, 
tr.ant-table-expanded-row:hover > td {
	background-color: #F7F7F7;
} */

.ant-timeline-item-last {
	padding-bottom: 0px;
}
.ant-timeline-item-tail {
	border-left: 2px dotted #BFBFBF;
}

/* ====================================== TABLE PAGINATION ====================================== */
.ant-pagination {
	font-family: 'Bold' !important;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
	border: 0px;
}
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
	background-color: #F5F5F5;
	border-radius: 100px;
}
.ant-pagination-item {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: 'Bold' !important;
	font-size: 16px;
	border: 0px;
}
.ant-pagination-item:hover a {
	color: #1C75BC;
}
.ant-pagination-item-active {
	background-color: #1C75BC;
	border-radius: 100px;
}
.ant-pagination-item-active a {
	color:#FFFFFF !important;
	text-decoration: none;
}
.ant-table-pagination.ant-pagination {
	margin: 0;
	margin-top: 24px;
}

/* ======================================= DYNAMIC TABLE ======================================= */
.TableLinks {
	font-family: 'Bold';
	font-size: 16px;
	text-transform: capitalize;
}

.PillTag {
	display:inline-block;
	font-family: 'Bold';
	font-size: 12px;
	background: #009298;
	color: #FFFFFF;
	border-radius: 100px;
	padding: 0px 12px 0px;
}

.LiveStatusTag {
	font-family: 'Bold';
	color: #009298;
	margin: 0;
}
/* 
.LocationDetails {
    font-family: 'Bold';
    margin-bottom: 24px;
}
.LocationData {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0px 24px;
    margin: 0;
}

.InfoWidth {
	width: 300px;
}
.Icon {
	width: 16px;
	height: 16px;
	margin-right: 8px;
}
.IconDescription {
	color:#757575;
	text-transform: capitalize;
	margin: 0;
	padding: 0;
}

.ant-timeline-item {
	padding-bottom: 24px;
}
.ant-timeline-item:last-child {
	padding-bottom: 0px;
} */


.OrderStatus {
	height: 48px;
	font-family: 'Bold';
	border-radius: 5px;
}

.OrderNumberLink {
	font-family: "Bold";
	font-size: 28px;
    color: #B3DFFF;
	-webkit-text-stroke: 0.5px #1C75BC;
}
.OrderNumberLinkHover:hover {
    color: #1C75BC;
}

.LineLimit3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

/* ========================================= SEARCH BAR ========================================= */
/* .InputField {
	height: 48px;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
} */



/* ==================================== DYNAMIC TABLE EXPAND ==================================== */
.ant-table-row-expand-icon {
	border: 1px solid #808080;
}

.ExpandedRow {
	padding: 6px 0px;
	border-bottom: 1px solid #E0E0E0;
}
.SummaryTable>p, .SummaryTable>div {
	padding: 3px 0px;
	border-bottom: 1px solid #E0E0E0;
}
.ExpandedRow:first-of-type {
	border-top: 1px solid #E0E0E0;
}
.ExpandedRow:last-of-type, .SummaryTable>div:last-of-type, .SummaryTable>p:last-of-type{
	border-bottom: 0px;
}


.OverflowEllipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}



.Icon {
	width: 20px;
	height: 20px;
	fill: #333333;
}

.MiniIcon {
	width: 16px;
	height: 16px;
	fill: #333333;
}

.InlineIcon {
	width: 12px;
	height: 12px;
	fill: #333333;
}

/* ======================================= DYNAMIC MODAL ======================================= */
.ant-modal-wrap {
	width: 100vw;
	height: 100vh;
	display: flex;
    align-items: center;
	justify-content: center;
}

.ant-modal {
	margin: 0px;
	padding: 0px;
	top: 0px;
}

.SquareModal { width: 70vh !important; }
.SquareModal > .ant-modal-content > .ant-modal-body {
	width: 70vh;
    max-height: calc(70vh - 144px) !important;
}

.RectangleModal { width: 70vw !important; }
.RectangleModal > .ant-modal-content > .ant-modal-body {
	width: 70vw;
    height: 35vw !important;
	overflow-y: scroll;
}

.StepsModal { width: 80vh !important; }
.StepsModal > .ant-modal-content > .ant-modal-body {
	width: 80vh;
    max-height: calc(80vh - 144px) !important;
}

.ant-modal-footer {
	width: 100%;
	height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
	padding: 0px 24px;
}

.ModalFooterButton,
.ModalFooterButton:hover, 
.ModalFooterButton:focus, 
.ModalFooterButton:enabled {
	width: 120px;
	height: 48px;
}


.ProfileButton,
.ProfileButton:hover, 
.ProfileButton:focus, 
.ProfileButton:enabled {
	width: 100%;
	height: 36px;
	border: 1px solid #F44336;
}

.DeleteButton {
	width: 48px;
	height: 48px;
}

.PreviousButton, .PreviousButton:hover, .PreviousButton:focus, .PreviousButton:enabled {
    color: #808080;
	background-color: #F2F2F2;
}

.CancelButton, .CancelButton:hover, .CancelButton:focus, .CancelButton:enabled {
    color: #F44336;
	background-color: #FFEBEE;
}

.SubmitButton, .SubmitButton:hover, .SubmitButton:focus, .SubmitButton:enabled {
	color: #FFFFFF;
	background-color: #00800B;
	border: 0px;
}

.PreviousButton::after { --antd-wave-shadow-color: #808080; }
.CancelButton::after { --antd-wave-shadow-color: #F44336; }
.SubmitButton::after { --antd-wave-shadow-color: #00800B; }



/* ======================================= FORM RENDERER ======================================= */
.InputField, 
.SelectField > .ant-select-selector, 
.NumberField > .ant-input-number-input-wrap, 
.CascaderField > .ant-cascader-input  {
	width: 100%;
	height: 48px !important;
	font-family: 'Regular';
	display: flex;
    align-items: center;
	border: 1px solid #E0E0E0;
	border-radius: 5px !important;
	color: #333333;
}
.TextAreaField {
	min-height: 96px !important;
}

.NumberField {
	width: 100%;
	border: 0px;
	border-radius: 5px !important;
}

.AutocompleteInputField>.InputField:focus {
	border-right-width: 1px !important;
	outline: 0;
	box-shadow: 0 0 0 2px rgb(24 144 255 / 20%) !important;
	border-color: #40a9ff !important;
	transition: box-shadow 0.5s;
}
.AutocompleteInputField>.InputField:hover {
	border-color: #40a9ff !important;
}



.SectionTitle {
	font-family: 'Bold';
	font-size: 14px !important;
	color: #003366 !important;
}

.SecondarySectionTitle {
	font-family: 'Bold';
	font-size: 14px !important;
	color: #006666 !important;
}

.SectionTitle.ant-divider-with-text {
	margin: 0px 0px 0px 0px !important;
	border-top-color: #003366 !important;
}

.SecondarySectionTitle.ant-divider-with-text {
	margin: 0px 0px 8px 0px !important;
	border-top-color: #006666 !important;
}

.ant-divider-inner-text {
	padding: 0px;
	padding-right: 12px;
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
	width: 0px;
}


.AutoHeight {
	height: auto !important;
}
.AutoWidth {
	width: auto !important;
}


.ant-upload.ant-upload-btn {
	background-color: #FFFFFF;
}



.Error.ant-card {
	border: 1px solid #F44336;
}
.Error > .ant-card-head  {
	background-color: #FFEBEE;
}

.Error > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title,
.Error > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title > div > div > .TitleDescription  {
	color: #F44336;
}


.HightlightInfo {
	position: sticky;
	top: 0px;
	font-family: 'Bold';
	height: 48px;
	display: flex;
	align-items: center;
	background-color: #1C75BC;
	color: #FFFFFF;
	padding: 6px 24px;
	z-index: 1;
}

.HightlightPillInfo {
	height: 100%;
	display:inline-block;
	border-radius: 100px;
	padding: 0px 12px;
}

.SummaryInfo {
	height: calc(100% - 48px);
	color: #333333;
}

.Title {
	font-family: 'Bold';
	font-size: 14px !important;
}

/* .RadioOption > span:first-child {
	display: none;
} */
.RadioOption > span:last-child {
	width: 100%;
}
.RadioOption>label {
	width: 100%;
	height: 100%;
	margin: 0px;
}
.RadioOption.ant-radio-wrapper-checked {
	background-color: #FFFFFF;
}
.RadioOption.Checked {
	background-color: #FFFFFF;
}

.FullHeightBody > .ant-card-body {
	height: calc(100% - 72px);
}

.ant-select-selection-search {
	height: 100%;
	display: flex;
	align-items: center;
}


.NoPointerEvent {
	pointer-events: none;
}



.VehicleIcon {
	height: 48px;
}

.ant-checkbox-group,
.ant-radio-group {
	width: 100%;
	height: 100%;
}
.FormCheckboxGroup > .ant-checkbox-wrapper,
.FormRadioGroup > .ant-radio-button-wrapper {
	width: calc(50% - 12px) !important;
	padding: 24px;
	margin-bottom: 24px;
	border-radius: 5px;
	border: 1px solid #E0E0E0;
}
.FormCheckboxGroup > .ant-checkbox-wrapper:nth-last-of-type(1),
.FormCheckboxGroup > .ant-checkbox-wrapper:nth-last-of-type(2),
.FormRadioGroup > .ant-radio-button-wrapper:nth-last-child(1),
.FormRadioGroup > .ant-radio-button-wrapper:nth-last-child(2){
	margin-bottom: 0px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
	display: none !important;
}
.ant-radio-button-wrapper:first-child, 
.ant-radio-button-wrapper:last-child {
	border-radius: 5px;
}

.FormCheckboxGroup > label:nth-of-type(odd),
.FormRadioGroup > label:nth-child(odd) {
	margin-right: 12px;
}
.FormCheckboxGroup > label:nth-of-type(even),
.FormRadioGroup > label:nth-child(even) {
	margin-left: 12px;
}

.ant-checkbox-group > .ant-checkbox-wrapper-checked,
.ant-radio-group > .ant-radio-button-wrapper-checked {
	background-color: #EDF7FF;
	border-color: #1C75BC;
}






.CollapsePanels > .ant-collapse {
	border: 0px;
	border-bottom: 0 !important;
	background-color: #ffffff;
}
.CollapsePanels > .ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding: 0 !important;
}
.CollapsePanels > .ant-collapse > .ant-collapse-item > .ant-collapse-content {
	border-radius: 0px 0px 5px 5px !important;
}

.CollapseHeader {
	font-family: 'Bold';
	background-color: #FAFAFA;
	color: #333333;
	border: 1px solid #E0E0E0;
	border-radius: 5px !important;
	margin-bottom: 12px;
}

.UploadImageBox {
	width: 33%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}
.UploadImage.ant-image-img {
	border-radius: 5px !important;
}



.LiveTrackingPanels {
	height: calc(100% - 72px);
	overflow: scroll;
}
.LiveTrackingPanels > .ant-collapse  {
	border: 0;
	background-color: transparent;
}
.LiveTrackingPanels > .ant-collapse > .ant-collapse-item {
	box-sizing: border-box;
	border-radius: 5px;
	margin-bottom: 24px;
}
.LiveTrackingPanels > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
	padding: 12px;
}
.LiveTrackingPanels > .ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding: 0px;
}
.LiveTrackingPanels > .ant-collapse > .ant-collapse-item > .ant-collapse-content {
	padding: 24px 12px 0px;
	border-radius: 0px 0px 5px 5px;
}


.PrimaryCollapsePanel {
	background: #EDF7FF;
	border: 1px solid #1C75BC !important;
}
.PrimaryCollapsePanel > .ant-collapse-content {
	border-top: 1px solid #1C75BC;
}

.SecondaryCollapsePanel {
	background: #E5FEFF;
	color: #00C2CB;
	border: 1px solid #00C2CB !important;
}
.SecondaryCollapsePanel > .ant-collapse-content {
	border-top: 1px solid #00C2CB;
}


.OrderStatusStyle {
	font-family: 'Bold';
	font-size: 36px;
}

.DriverTrackingHeader {
	height: 72px;
	font-family: 'Bold';
	font-size: 16px;
	background: #EDF7FF;
	padding: 0px 24px;
	border-bottom: 1px solid #E0E0E0; 
}

.OrderStickyInfo {
	min-width: 168px;
	height: 48px;
	padding: 0px 6px;
	border-radius: 5px;
}

.ContainerNumber {
	font-family: 'Bold';
	font-size: 28px;
    color: #E5FEFF;
	-webkit-text-stroke: 1px #009298;
}


.StatusTimer>.ant-statistic-content {
	font-family: 'Regular';
	font-size: 14px;
	color: #808080;
}


.ant-cascader-menu {
	min-width: 250px;
	min-height: 250px;
}





















/* ==================================== ANTD STEPS SETTINGS ==================================== */
.ant-steps {
    background: #FFFFFF;
    padding: 12px 24px;
	border-bottom: 1px solid #E0E0E0;
}

.ant-steps-item-title, 
.ant-steps-item-icon {
    font-family: 'Bold';
}

.ant-steps-item-icon > .ant-steps-icon { top: 0px; } 

.ant-steps-item-title::after { background-color: #333333 !important; }

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-process .ant-steps-item-icon {
    background: #2E94FA;
    border-color: #2E94FA;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background: #0B8000;
    border-color: #0B8000;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #FFFFFF;
    top: 0.5px;
}


.FormCheckboxGroup > label > span:last-of-type {
	width: 100%;
}

.StepCounter {
	border-radius: 5px;
	margin: 0px 6px;
}